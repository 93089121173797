<template>
    <footer class="main-footer font-xs">
        <div class="row pb-30 pt-15">
            <div class="col-sm-6">
                2023 &copy; Movenpick.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end">All rights reserved</div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterVue',
}
</script>

<style>

</style>