<template>
    <div v-if="loading" class="img-loader"></div>
    <div v-if="!loading">
        <div class="screen-overlay" id="screen-overlay"></div>
        <Logo />

        <main class="main-wrap">
            <NavbarVue />
            <section class="content-main">
                <PageTitle title="Upload Penawaran" desc="Download Template and Upload Penawaran Data." :isShow="show" />

                <div class="card card-body mb-4">
                <div class="row gx-3" style="position: relative;gap: 10px;">
                    <div class="col-lg-2 col-6 col-md-3">
                        <select class="form-select">
                            <option>All category</option>
                        </select>
                    </div>

                    <!-- <div class="col-lg-2 col-5 col-md-3">
                        <select class="form-select" v-model="itemPerPage">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </div> -->
                </div>

                <div style="position: absolute;right:0;">
                    <button class="btn btn-danger rounded font-md"
                        style="margin-right: 10px;">
                        <i style="margin-top: 2px;" class="fa-solid fa-file-arrow-down"></i>
                        <span>Download</span>
                    </button>
                </div>
            </div>

            <div class="flex w-full h-screen items-center justify-center text-center" 
                    id="app">
                <div class="p-12 bg-gray-100" 
                    @dragover="dragover" 
                    @dragleave="dragleave" 
                    @drop="drop"
                    style="position:relative;
                    height: 300px;
                    border: 3px dashed var(--graybold);
                    border-radius: 15px;
                    background: var(--gray);">

                    <button class="btn-upload">
                        Browse File
                        <input type="file" multiple name="fields[assetsFieldHandle][]" 
                            id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" 
                            @change="onChange" ref="file" accept=".xlsx,.xlsv," 
                            style="opacity: 0;
                                    position: absolute;
                                    z-index: 10;
                                    cursor: pointer;"/>
                    </button>
                
                    <label for="assetsFieldHandle" class="block cursor-pointer">
                    
                    <div style="display: flex;
                                flex-direction: column;
                                font-size: 18pt;
                                margin-top: 20px;
                                font-family: 'BORELA', sans-serif;">
                        <i style="font-size: 70pt;color: var(--bluecolor);opacity: 0.5;" 
                        class="fa-solid fa-cloud-arrow-up"></i>

                        <span style="margin-top: 20px;">Drag and Drop File Here</span>
                        <span>or</span> 
                    </div>

                    </label>

                    <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                        <li class="text-sm p-1" v-for="file in filelist" :key="file">
                            <button class="ml-2 btn-upload" type="button" 
                            @click="remove(filelist.indexOf(file))" 
                            title="Remove file">Remove</button>

                            <span style="position: absolute;
                                         bottom: 15px;
                                         left: 50%;
                                         -webkit-transform: translateX(-50%);
                                         transform: translateX(-50%);">
                                         {{ file.name }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            </section>

            <Footer />
        </main>
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import NavbarVue from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import PageTitle from '@/components/PageTitle.vue'

export default {
    name: 'HomeView',
    components: {
        Logo,
        NavbarVue,
        Footer,
        PageTitle,
    },
    delimiters: ['${', '}'],
    data() {
        return{
            filelist: [],
            show: false,
        }
    },
    methods: {
        onChange() {
            this.filelist = [...this.$refs.file.files];
            this.show = true;
        },
        remove(i) {
            this.filelist.splice(i, 1);
            this.show = false;
        },
        dragover(event) {
        event.preventDefault();
        // Add some visual fluff to show the user can drop its files
        if (!event.currentTarget.classList.contains('bg-green-300')) {
            event.currentTarget.classList.remove('bg-gray-100');
            event.currentTarget.classList.add('bg-green-300');
        }
        },
        dragleave(event) {
        // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },
        drop(event) {
        event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        }
    }
}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/borela');

.btn-upload{
    position: absolute;
    width: 150px;
    height: 35px;
    left: 50%;
    bottom: 40px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    background: var(--bluecolor);
    border: none;
    color: var(--white);
}

.btn-upload:hover{
    background: var(--bluehover);
}
</style>
  