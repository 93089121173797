<template>
    <success-alert v-if="success" 
        :message="messages">
    </success-alert>

    <div v-if="loading" class="img-loader"></div>
    <div v-if="!loading">
        <div class="screen-overlay" id="screen-overlay"></div>
        <Logo />

        <main class="main-wrap">
            <NavbarVue />
            <section class="content-main">
                <div class="content-header">
                    <a href="javascript:history.back()" style="display: flex;align-items: center;">
                        <i class="material-icons md-arrow_back"></i>
                        <span style="font-size: 13pt;font-weight: bold;"> Back</span>
                    </a>
                </div>
                <PageTitle v-if="desc" title="Order Detail" :desc="desc" />

                <div class="card">
                    <div style="position: absolute;
                                top: 0;
                                left:0;
                                width: 100%;
                                height: 100%;
                                font-size: 150pt;
                                font-weight: bolder;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: var(--overlay);
                                z-index: 10;
                                color: var(--white);
                                opacity: 0.3;"
                                v-if="po.batal">
                        <span>Canceled</span>
                    </div>

                    <header class="card-header">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 mb-lg-0 mb-15">
                                <span> <i class="material-icons md-calendar_today"></i> 
                                    <b style="position: relative;top: -5px;
                                       margin-left: 10px;">{{ po.po_date }}</b> 
                                </span> <br />
                                <small class="text-muted">PO Number: #{{ this.$route.params.id }}</small>
                            </div>
                            <div class="col-lg-6 col-md-6 ms-auto text-md-end">
                                <a class="btn btn-danger" @click="show_modal = true"
                                   v-if="String(po.user).replace(',', '') === this.$store.getters.GET_AUTH_INFO.id && !po.batal">
                                   Cancel
                                </a>

                                <!-- <a class="btn btn-primary" style="margin-left: 10px">Complete</a> -->
                                <!-- <a class="btn btn-secondary print ms-2" href="#"><i class="icon material-icons md-print"></i></a> -->
                            </div>
                        </div>
                    </header>

                    <div class="card-body">
                        <div class="row mb-50 mt-20 order-info-wrap">
                            <div class="col-md-4">
                                <article class="icontext align-items-start">
                                    <span class="icon icon-sm rounded-circle bg-primary-light"
                                          style="border-radius: 50%;overflow: hidden;">
                                        <img :src="getImage(image)" alt="">
                                    </span>
                                    <div class="text">
                                        <h6 class="mb-1">Customer</h6>
                                        <p class="mb-1">
                                            {{ po.user_name }} <br />
                                            {{ String(po.user).replace(",", "") }}@movenpick.com <br />
                                            {{ po.user_group }}
                                        </p>
                                        
                                    </div>
                                </article>
                            </div>
                            
                            <div class="col-md-4">
                                <article class="icontext align-items-start">
                                    <span class="icon icon-sm rounded-circle bg-primary-light">
                                        <i class="text-blue fa-solid fa-box"></i>
                                    </span>
                                    <div class="text">
                                        <h6 class="mb-1">Supplier</h6>
                                        <p class="mb-1">
                                            {{ po.supplier }} <br />
                                            Address:  <br />
                                            Contact: 
                                        </p>
                                        
                                    </div>
                                </article>
                            </div>
                            
                            <div class="col-md-4">
                                <article class="icontext align-items-start">
                                    <span class="icon icon-sm rounded-circle bg-primary-light">
                                        <i class="text-blue material-icons md-place"></i>
                                    </span>
                                    <div class="text">
                                        <h6 class="mb-1">Deliver to</h6>
                                        <p class="mb-1">
                                            Divisi :  <br />
                                            Subdivisi :  <br />
                                            Department : 
                                        </p>
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th width="10%">#ID</th>
                                                <th width="30%">Product</th>
                                                <th class="text-end" width="20%">Unit Price</th>
                                                <th class="text-center" width="10%">Quantity</th>
                                                <th class="text-center" width="10%">Discount</th>
                                                <th width="20%" class="text-end">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in details" :key="item.id">
                                                <td>{{ item.kd_brg }}</td>
                                                <td>
                                                    <a class="itemside">
                                                        <div class="left">
                                                            <!-- <img src="assets/imgs/items/1.jpg" 
                                                                 width="40" 
                                                                 height="40" 
                                                                 class="img-xs" 
                                                                 alt="Item" /> -->
                                                        </div>
                                                        <div class="info">{{ item.desc }}</div>
                                                    </a>
                                                </td>
                                                <td class="text-end">Rp. {{ formatPrice(item.harga) }}</td>
                                                <td class="text-center">{{ item.qty }}</td>
                                                <td class="text-center">{{ item.disc }}</td>
                                                <td class="text-end">Rp. {{ formatPrice(item.qty * (item.harga - ((item.harga * item.disc) / 100))) }}</td>
                                            </tr>

                                            <tr>
                                                <td colspan="6">
                                                    <article class="float-end">
                                                        <dl class="dlist">
                                                            <dt>Subtotal:</dt>
                                                            <dd>Rp. {{ formatPrice(total) }}</dd>
                                                        </dl>
                                                        <dl class="dlist">
                                                            <dt>Discount:</dt>
                                                            <dd>Rp. {{ formatPrice(discount) }}</dd>
                                                        </dl>
                                                        <dl class="dlist">
                                                            <dt>Grand total:</dt>
                                                            <dd><b class="h5">Rp. {{ formatPrice(grand) }}</b></dd>
                                                        </dl>
                                                        <dl class="dlist">
                                                            <dt class="text-muted">Status:</dt>
                                                            <dd>
                                                                <span class="badge rounded-pill alert-success text-success"
                                                                      v-if="!po.batal">
                                                                    Complete
                                                                </span>
                                                                <span class="badge rounded-pill alert-danger text-white"
                                                                      v-if="po.batal">
                                                                    Canceled
                                                                </span>
                                                            </dd>
                                                        </dl>
                                                    </article>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </section>

            <Footer />
        </main>
    </div>

    <div v-if="show_modal" class="alert-modal">
        <div class="screen-overlays" @click="show_modal = false"></div>
        <div class="modal-card shake-r">
            <span style="font-size: 18pt;">Are You Sure ?</span>
            <span style="font-size: 16pt;">You won't be able to revert this!</span>
            <div class="modal-btn">
                <button class="btn btn-warning text-white"
                        @click="cancelPo">Yes, Delete it!
                </button>

                <button class="btn btn-danger"
                        @click="show_modal = false">Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import NavbarVue from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import PageTitle from '@/components/PageTitle.vue'
import SuccessAlert from '@/components/SuccessAlert.vue'
import axios from 'axios'

export default {
    name: 'OrderDetailView',
    components: {
        Logo,
        NavbarVue,
        Footer,
        PageTitle,
        SuccessAlert,
    },
    data(){
        return {
            image: null,
            desc: null,
            po: {},
            details: [],
            total: 0,
            grand: 0,
            discount: 0,
            success: false,
            messages: null,
            show_modal: false,
        }
    },
    mounted(){
        this.desc = 'Details for Order ID: ' + this.$route.params.id
        this.getProfile()
        this.getPODetail()
    },
    methods: {
        getProfile(){
            this.image = this.$store.getters.GET_AUTH_INFO.image
        },
        getImage(filename){
            return this.uri + '/image-profile/' + filename
        },
        async getPODetail(){
            try {
                const { data } = await axios.get('/podetail/' + this.$route.params.id + '/' +
                    this.$route.params.supplier + '/' + this.$store.getters.GET_AUTH_TOKEN
                )

                console.log(data)
                this.po = data
                this.details = data.item
                this.details.forEach((data) => {
                    const harga = data.qty * data.harga
                    const disc = data.qty * ((data.harga * data.disc) / 100)
                    const gt = harga - disc

                    this.total += harga
                    this.discount += disc
                    this.grand += gt
                })
            } catch(e){
                console.log(e)
            }
        },
        formatPrice(value){
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        async cancelPo(){
            try{
                await axios.delete('/po/' + this.$store.getters.GET_AUTH_INFO.id + '/' +
                    this.$store.getters.GET_AUTH_TOKEN, { data: {
                        po_no: this.$route.params.id
                    }}
                )

                this.messages = 'PO Canceled!'
                this.show_modal = false
                this.success = true
                this.getPODetail()
                setTimeout(() => {
                    this.success = false
                    this.messages = null
                }, 1000);
            } catch(e){
                console.log(e)
            }
        }
    }
}
</script>

<style>

</style>
  