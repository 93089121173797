<template>
    <aside class="navbar-aside ps" id="offcanvas_aside">
        <div class="aside-top">
            <router-link :to="{name: 'home'}" class="brand-wrap" 
               style="display: flex;align-items: center;">
                <!-- <img style="width: 40px;margin-right: 10px;" src="/logo.png" alt="Movenpick" /> 
                <div style="width: 2px;height: 40px;background: #545454;"></div> -->
                <img style="height: 35px;margin-left: 10px;" 
                     src="/assets/imgs/logo/movenpick.png" alt="logo"
                     v-if="!isHide && screenWidth > 768">

                <img style="height: 25px;margin-left: 10px;" 
                     src="/assets/imgs/logo/movenpick.png" alt="logo"
                     v-if="screenWidth < 768">
            </router-link>
            
            <div>
                <button v-if="screenWidth > 768" class="btn btn-icon btn-aside-minimize"
                        style="font-size: 18pt;color: var(--bluecolor);" @click="showSidebar">
                        <i class="fa-solid fa-bars"></i>
                </button>

                <button v-if="screenWidth < 768" class="btn btn-icon btn-aside-minimize"
                        style="font-size: 18pt;color: var(--bluecolor);" @click="hideMenu">
                        <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
        </div>

        <Sidebar />
    </aside>
</template>

<script>
import Sidebar from './Sidebar.vue';
export default {
    name: 'LogoVue',
    components: {
        Sidebar,
    },
    data(){
        return {
            isMinimize: false,
            screenWidth: 0,
            isHide: false,
        }
    },
    mounted(){
        this.getProperty()
    },
    methods: {
        getProperty(){
            this.screenWidth = window.innerWidth
            this.isHide = this.$store.state.isHide
        },
        showSidebar(){
            if(!this.isHide){
                this.$store.commit("update_hide", true)
                this.isHide = this.$store.state.isHide
                document.body.classList.add("aside-mini");
            } else {                
                this.$store.commit("update_hide", false)
                this.isHide = this.$store.state.isHide
                document.body.classList.remove("aside-mini");
            }
        },
        hideMenu(){
            document.body.classList.remove("offcanvas-active");
            document.getElementById("screen-overlay").classList.remove("show")
            document.getElementById("offcanvas_aside").classList.remove("show")
        },  
    }
}
</script>

<style>

</style>