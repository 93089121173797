<template>
    <div v-if="loading" class="img-loader"></div>
    <div v-if="!loading">
        <div class="screen-overlay" id="screen-overlay"></div>
        <Logo />

        <main class="main-wrap">
            <NavbarVue />

            <section class="content-main">
                <div class="content-header">
                    <a href="javascript:history.back()" style="display: flex;align-items: center;">
                        <i class="material-icons md-arrow_back"></i>
                        <span style="font-size: 13pt;font-weight: bold;"> Back</span>
                    </a>
                </div>

                <PageTitle title="Order List" desc="Order list from all request." />

                <div class="card mb-4">
                    <header class="card-header">
                        <div class="row gx-3">
                            <div class="col-lg-4 col-md-6 me-auto">
                                <input type="text" placeholder="Search..." class="form-control" />
                            </div>
                            <div class="col-lg-2 col-md-3 col-6">
                                <select class="form-select">
                                    <option>Assets</option>
                                    <option>Expense</option>                                    
                                </select>
                            </div>
                        </div>
                    </header>
                    
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>PO</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>Jenis PO</th>
                                        <th class="text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in po" :key="item.po_no">
                                        <td width="40%">
                                            <a class="itemside">
                                                <div class="info pl-3">
                                                    <h6 class="mb-0 title">{{ item.supplier }}</h6>
                                                    <small class="text-muted">PO Number: #{{ item.po_no }}</small>
                                                </div>
                                            </a>
                                        </td>
                                        <td>{{ item.desc }}</td>
                                        <td>
                                            <span class="badge rounded-pill alert-success" v-if="!item.batal">Success</span>
                                            <span class="badge rounded-pill alert-danger text-white" v-if="item.batal">Canceled</span>
                                        </td>
                                        <td>{{ String(item.jenis).charAt(0).toUpperCase() + String(item.jenis).slice(1) }}</td>
                                        <td class="text-end">
                                            <router-link :to="{name: 'order-detail', params: {id: item.po_no, supplier: item.supplier}}"
                                                class="btn btn-sm btn-brand rounded font-sm mt-15">
                                                View details
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <!-- <div class="pagination-area mt-15 mb-50">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-start">
                            <li class="page-item active"><a class="page-link" href="#">01</a></li>
                            <li class="page-item"><a class="page-link" href="#">02</a></li>
                            <li class="page-item"><a class="page-link" href="#">03</a></li>
                            <li class="page-item"><a class="page-link dot" href="#">...</a></li>
                            <li class="page-item"><a class="page-link" href="#">16</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#"><i class="material-icons md-chevron_right"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div> -->
            </section>
           
            <Footer />
        </main>
    </div>
</template>

<script>
import NavbarVue from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import PageTitle from '@/components/PageTitle.vue'
import Logo from '@/components/Logo.vue'
import axios from 'axios'

export default {
    name: 'OrderListView',
    components: {
        NavbarVue,
        Footer,
        PageTitle,
        Logo,
    },
    data(){
        return {
            po: [],
            loading: true,
        }
    },
    mounted(){
        this.getPO()
    },
    methods: {
        async getPO(){
            try {
                const { data } = await axios.get('/po/' + this.$store.getters.GET_AUTH_INFO.id + '/' +
                    this.$store.getters.GET_AUTH_TOKEN
                )

                console.log(data)
                this.po = data
                setTimeout(() => {
                    this.loading = false
                }, 1200)
            } catch(e){
                console.log(e)
            }
        },
        formatPrice(value){
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    }
}
</script>

<style>

</style>