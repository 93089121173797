<template>
    <div class="container" style="display: flex;flex-direction: column;">
        <div class="screen">
            <div class="screen__content">
                <div class="login">
					<img style="width: 180px;margin-top: -20px;" src="/assets/imgs/logo/movenpick.png" alt="">
                    <div class="login__field">
                        <i class="login__icon fas fa-user"></i>
                        <input type="text" class="login__input" 
                               placeholder="User ID" v-model="userid"
                               @keypress.enter="login">
                    </div>

                    <div class="login__field">
                        <i class="login__icon fas fa-lock"></i>
                        <input type="password" class="login__input" id="password"
                               placeholder="Password" v-model="password"
                               @keypress.enter="login">
                        <div style="position: relative;" @click="showPass">
                            <i v-if="!showPassword" class="fa-solid fa-eye show-password" id="show-password"></i>
                            <i v-if="showPassword" class="fa-solid fa-eye-slash show-password" id="show-password"></i>
                        </div>
                    </div>

                    <button class="button login__submit" @click="login">
                        <span class="button__text">LogIn</span>
                        <i class="button__icon fas fa-chevron-right"></i>
                    </button>				
                </div>
            </div>
			
            <div class="screen__background">
                <span class="screen__background__shape screen__background__shape4"></span>
                <span class="screen__background__shape screen__background__shape3"></span>		
                <span class="screen__background__shape screen__background__shape2"></span>
                <span class="screen__background__shape screen__background__shape1"></span>
            </div>		
        </div>

        <div v-if="showSuccess" class="alert-box bg-done bounce-in-r">
            <div class="alert-message">
                <i class="fa-solid fa-check"
                   style="font-size:30pt;color: var(--white);"></i>
                <span style="margin-top: 7px;color: var(--white);
                      font-weight: bold;">
                      Log In Success
                </span> 
            </div>

            <div class="progress-stripped">    
                <div class="progress-bar-success"></div>
            </div>
        </div>

        <div v-if="showAlert" class="alert-box bg-error bounce-in">
            <div class="close-btn">
                <i class="fa-solid fa-xmark"
                   style="cursor: pointer;"
                   @click="closeAlert">
                </i>
            </div>

            <div class="alert-message">
                <i class="fa-solid fa-triangle-exclamation"
                   style="font-size:30pt;color: var(--white);"></i>
                <span style="margin-top: 7px;color: #545454;
                      font-weight: bold;">{{ message }}
                </span> 
            </div>

            <div class="progress-stripped">    
                <div class="progress-bar-error"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginView',
    data(){
        return{
            userid: null,
            password: null,
            showPassword: false,
            error_userid: null,
            error_password: null,
            is_proccess: false,
            showAlert: false,
            message: null,
            showSuccess: false,
        }
    },
    methods: {
        closeAlert(){
            this.showAlert = false
            this.message = null
        },
        showPass(){
          const eyes = document.getElementById("show-password");
          if(this.showPassword === false) {
              this.showPassword = true;
              document.getElementById("password").type = 'text';
              eyes.style.color = "var(--basecolor)"
          } else {
              this.showPassword = false;
              document.getElementById("password").type = 'password';
              eyes.style.color = "var(--brownhover)"
          }
      },
      login(){
        if(this.is_proccess) return;
        this.is_proccess = true;

        if(!this.userid) {
            this.message = "Field userid harus diisi!";
            this.show = true

            setTimeout(() => {
                this.message = null;
                this.showAlert = false;
            }, 4000);

            this.is_proccess = false;
            return;
        }

        if(!this.password) {
            this.message = "Field password harus diisi";
            this.showAlert = true;

            setTimeout(() => {
                this.message = null;
                this.showAlert = false;
            }, 4000);

            this.is_proccess = false;
            return;
        }

        const data = {
          id: String(this.userid),
          password: this.password,
        }

        if(this.userid && this.password){
          this.$store.dispatch("LOGIN", data)
          .then((res) => {
              this.showSuccess = true
              this.is_proccess = false
              console.log(res)
              setTimeout(() => {
                this.$router.push({ name : 'home'});
              }, 1100)
          }).catch((error) => {
              this.message = error.response.data.message
              this.showAlert = true

              console.log(error)
              setTimeout(() => {
                    this.message = null;
                    this.showAlert = false;
              }, 4000);
              this.is_proccess = false;
          })  
        }
      },
    }
}
</script>

<style>
.show-password{
    position:absolute;
    top: -30px;
    right: 60px;
    font-size: 14pt;
    cursor: pointer;
    color: var(--brownhover);
}

.show-password-active{
    color: var(--basecolor);
}

.progress-stripped{
    position: absolute;
    bottom: 0;
    left:0;
    width: 100%;
}

.bg-error{
    background-color: var(--yellow);
    border-color: var(--yellowhover);
}

.bg-done{
    background-color: #6ad051;
}

.alert-box{
    position: relative;
    top: 20px;
    width: 98%;
    max-width: 400px;
    height: 100px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.alert-box .close-btn{
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 21pt;
    color: var(--white);
}

.alert-box .alert-message{
    position: relative;
    width: 90%;
    margin: 0 auto;
    top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12pt;
}

.progress-bar-error { 	
    background-color: var(--basecolor);
    width: 0%; 
    height: 5px;
    animation: progressAnimationStrike 3.9s;
}

.progress-bar-success { 	
    background-color: var(--white);
    width: 0%; 
    height: 5px;
    animation: progressAnimationStrike 1s;
}

@keyframes progressAnimationStrike {
     from { width: 100% }
     to   { width: 0% }
}
</style>