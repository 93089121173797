<template>
    <div style="position: fixed;
                width: 100vw;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 1000;">
        <div class="alert-box bg-done fade-in-down" 
            style="position: relative;margin: 0 auto;">
            <div class="alert-message">
                <i class="fa-solid fa-check"
                    style="font-size:30pt;color: var(--white);"></i>
                <span style="margin-top: 7px;color: var(--white);
                        font-weight: bold;">
                        {{ message }}
                </span> 
            </div>

            <div class="progress-stripped">    
                <div class="progress-bar-success"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SucessAlert',
    props: ['message']
}
</script>

<style>

</style>