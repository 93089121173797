<template>
    <success-alert v-if="success" 
        :message="messages">
    </success-alert>

    <div v-if="loading" class="img-loader"></div>

    <div v-if="!loading">
        <div class="screen-overlay" id="screen-overlay"></div>
        <Logo />

        <main class="main-wrap">
            <NavbarVue />

            <section class="content-main">
                <div class="content-header">
                    <a href="javascript:history.back()" style="display: flex;align-items: center;">
                        <i class="material-icons md-arrow_back"></i>
                        <span style="font-size: 13pt;font-weight: bold;"> Back</span>
                    </a>
                </div>

                <PageTitle title="Cart List" desc="Items list to be purchased." :isShow="showbtn" @submitted="onSubmitted" />

                <div class="card mb-4">
                    <header class="card-header">
                        <div class="row gx-3">
                            <div class="col-lg-4 col-md-6 me-auto">
                                <input type="text" placeholder="Search..." class="form-control" />
                            </div>
                            <div class="col-lg-2 col-6 col-md-3">
                                <select class="form-select">
                                    <option>Status</option>
                                    <option>Active</option>
                                    <option>Disabled</option>
                                    <option>Show all</option>
                                </select>
                            </div>
                        </div>
                    </header>
                    

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Product ID</th>
                                        <th scope="col">Image</th>
                                        <th scope="col" style="width: 200px;">Product Name</th>
                                        <th scope="col" style="width: 200px;">Description</th>
                                        <th scope="col text-center">Total</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Supplier</th>
                                        <th scope="col" class="text-start">Action</th>
                                    </tr>
                                </thead>

                                <tbody v-if="pagecart.length <= 0">
                                    <tr>
                                        <td colspan="8" 
                                            style="text-align: center;
                                            margin-top: 10px;
                                            font-size: 12pt;
                                            font-weight: bold;">
                                            No Data
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody v-for="item in pagecart[current_page]" :key="item.id"
                                       :class="{'bg-decline' : item.is_cancel}"
                                       style="border-bottom: 1px solid #626262;">
                                    <tr>
                                        <td>{{ item.kd_brg_supp }}</td>
                                        <td>
                                            <div style="width: 70px;
                                                        height: 70px;
                                                        border-radius: 5px;
                                                        background: var(--white);
                                                        overflow: hidden;">
                                                <img style="width: 100%;object-fit: contain;" 
                                                     :src="getProductImage(item.image_id)" alt="">
                                            </div>
                                        </td>
                                        <td><b>{{ item.nm_brg_supp }}</b></td>
                                        <td>{{ item.desc_brg_supp }}</td>
                                        <td class="text-center">{{ item.qty }}</td>
                                        <td>{{ item.category }}</td>
                                        <td>{{ item.supp_kd }}</td>
                                        <td style="display: flex;
                                            flex-wrap: wrap;
                                            /* justify-content: end; */
                                            gap: 5px;">
                                            <router-link :to="{name: 'penawaran', 
                                                        params: {
                                                            reqid: item.request_id, 
                                                            kdbrg: '1' + String(item.kd_brg), 
                                                            area: 'ADD'
                                                    }}" style="margin-right: 5px;
                                                               width: 72px;
                                                               height: 37px;
                                                               margin-top: 0px;
                                                               font-weight: bold;" 
                                                    class="btn btn-info">
                                                <i style="font-size: 12pt;margin-top: 2px;" 
                                                   class="material-icons md-launch"></i> 
                                                   Edit
                                            </router-link>

                                            <button class="btn btn-md btn-primary" 
                                                    @click="deleteCart(item.cart_id)">
                                                    Delete
                                            </button>
                                        </td>
                                    </tr>

                                    <tr v-if="item.is_cancel">
                                        <td style="font-weight: bold" colspan="2">
                                            Cancellation Reason
                                        </td>
                                        <td style="font-weight: bold" colspan="6">
                                            {{ item.cancel_reason }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                

                <div class="pagination-area mt-15 mb-50">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-start">
                            <li class="page-item" v-if="start_slice >= 5"
                                @click="prevPagination">
                                <a class="page-link">
                                    <i class="material-icons md-chevron_left"
                                    style="margin-top: 3px;font-size: 20pt;
                                            margin-left: -4px;"></i>
                                </a>
                            </li>

                            <li class="page-item" :class="{'active' : current_page == pg}"
                                v-for="pg in total_page.slice(start_slice, end_slice)" :key="pg"
                                @click="current_page = pg">
                                <a class="page-link">{{ pg + 1 }}</a>
                            </li>
                            
                            <li class="page-item" v-if="end_slice < total_page.length"
                                @click="nextPagination">
                                <a class="page-link">
                                    <i class="material-icons md-chevron_right"
                                    style="margin-top: 3px;font-size: 20pt;
                                            margin-left: -4px;"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </section>
           
            <Footer />
        </main>
    </div>

    <div v-if="show_modal" class="alert-modal">
        <div class="screen-overlays" @click="show_modal = false"></div>
        <div class="modal-card shake-r">
            <span style="font-size: 18pt;">Are You Sure ?</span>
            <span style="font-size: 16pt;">You won't be able to revert this!</span>
            <div class="modal-btn">
                <button class="btn btn-warning text-white"
                        @click="confirmDelete">Yes, Delete it!
                </button>

                <button class="btn btn-danger"
                        @click="show_modal = false">Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SuccessAlert from '@/components/SuccessAlert.vue'
import NavbarVue from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import PageTitle from '@/components/PageTitle.vue'
import Logo from '@/components/Logo.vue'
import axios from 'axios'

export default {
    name: 'CartView',
    components: {
        NavbarVue,
        Footer,
        PageTitle,
        Logo,
        SuccessAlert,
    },
    data(){
        return{
            cartitem: [],
            pagecart: '',
            total_page: [],
            itemPerPage: 10,
            loading: true,
            start_slice: 0,
            end_slice: 10,
            current_page: 0,
            success: false,
            selected_item: null,
            show_modal: false,
            success_submitted: false,
            showbtn : false,
            messages: null,
        }
    },
    mounted(){
        this.getCartItem()
    },
    methods: {
        async getCartItem(){
            try{
                const { request, data } = await axios.get('/cart/' + this.$store.getters.GET_AUTH_INFO.id + 
                                        "/" + this.$store.getters.GET_AUTH_TOKEN)

                this.cartitem = [];
                this.pagecart = [];
                this.total_page = [];
                this.cartitem = data
                const groupSize = this.itemPerPage;
                for (let i = 0; i < this.cartitem.length; i += groupSize) {
                    this.pagecart.push(this.cartitem.slice(i, i + groupSize));
                }
                
                for(var i = 0; i < this.pagecart.length; i++){
                    this.total_page.push(i)
                }

                if(request.status == 200){
                    this.showbtn = true
                } else {
                    this.showbtn = false
                }

                console.log(this.pagecart)
                setTimeout(() => {this.loading = false}, 1200)
            } catch(e){
                console.log(e)
                setTimeout(() => {this.loading = false}, 1200)
            }
        },
        prevPagination(){
            if(this.start_slice <= 0) return;

            this.start_slice -= 5
            this.end_slice -= 5
        },
        nextPagination(){
            if(this.end_slice > this.total_page.length) {
                this.start_slice = this.total_page.length - 5
                this.end_slice = this.total_page.length
                return;
            }

            this.start_slice += 5
            this.end_slice += 5
        },
        async confirmDelete(){
            try {
                await axios.delete('/cart/' + this.$store.getters.GET_AUTH_INFO.id + '/'
                                    + this.$store.getters.GET_AUTH_TOKEN, { data: {
                                            cart_id: this.selected_item 
                                        }
                                    })

                this.messages = 'Item deleted successfully'
                this.show_modal = false
                this.success = true
                setTimeout(() => {
                    this.success = false
                    this.messages = null
                    this.getCartItem()
                }, 1100)
            } catch(e){
                console.log(e)
            }
        },
        deleteCart(cartid){
            this.selected_item = cartid
            this.show_modal = true
        },
        getProductImage(filename){
            return this.uri + '/image/' + filename
        },
        onSubmitted(value){
            this.messages = 'Item submitted!'
            this.success = value
            setTimeout(() => {
                this.success = false
                this.messages = null
                this.getCartItem()
            }, 1000);
        }
    }
}
</script>

<style>
.alert-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen-overlays{
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay);
}

.modal-card{
    position: relative;
    width: 90%;
    max-width: 500px;
    padding: 20px 0;
    background: var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-btn{
    position: relative;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
    gap: 10px
}
</style>