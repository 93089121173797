<template>
    <success-alert v-if="selected" message="Item selected"></success-alert>
    
    <div v-if="loading" class="img-loader"></div>
    <div v-if="!loading">
        <div class="screen-overlay" id="screen-overlay"></div>
        <Logo />
        <main class="main-wrap">
            <NavbarVue />
            <section class="content-main">
                <div class="content-header">
                    <a href="javascript:history.back()" style="display: flex;align-items: center;">
                        <i class="material-icons md-arrow_back"></i>
                        <span style="font-size: 13pt;font-weight: bold;"> Back</span>
                    </a>
                </div>
                <PageTitle title="Product Comparison" desc="Product list from all supplier." />
                <div class="card mb-4">
                    <div class="card-header bg-brand-2" style="height: 150px">
                        <img style="position: absolute; 
                                    top:20px;
                                    width: 30%;
                                    left:50%;
                                    -webkit-transform: translateX(-50%);
                                    transform: translateX(-50%);" 
                                    src="/assets/imgs/logo/movenpick.png" 
                                    alt="logo">
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl col-lg flex-grow-0" style="flex-basis: 230px">
                                <div class="img-thumbnail shadow w-100 bg-white position-relative text-center" 
                                     style="height: 190px; 
                                            width: 200px; 
                                            margin-top: -120px; 
                                            background: #696969;
                                            border-radius: 5px;">

                                    <img :src="getProductImage(image)" 
                                         class="center-xy" 
                                         :alt="image" 
                                         style="width: 120px;
                                         object-fit: contain;"/>
                                </div>
                            </div>
                            
                            <div class="col-xl col-lg">
                                <h3>{{ nama_barang }}</h3>
                                <p>{{ description }}</p>
                                <p style="color: var(--bluecolor);">{{ description1 }}</p>
                            </div>
                            
                            <div class="col-xl-6 text-md-end" v-if="is_selected">
                                <a v-if="user_choosen === this.$store.getters.GET_AUTH_INFO.id || !user_choosen" class="btn btn-danger" 
                                   style="font-size: 12pt;" @click="submitCart"> 
                                    Save Selected 
                                    <i style="margin-top: 2px;
                                              margin-left: 10px;
                                              font-size: 15pt;
                                              margin-right: 10px;" 
                                       class="fa-solid fa-floppy-disk">
                                    </i>
                                </a>

                                <!-- <a v-if="selected_product" class="btn btn-danger" style="font-size: 12pt;" @click="submitCart"> Unselect All
                                    <i style="margin-top: 2px;margin-left: 10px;font-size: 15pt;" 
                                       class="fa-solid fa-floppy-disk"></i>
                                </a> -->
                            </div>
                        </div>
                        
                        <hr class="my-4" />
                        <div class="row g-4">
                            <div class="col-md-12 col-lg-4 col-xl-2">
                                <article class="box">
                                    <p class="mb-0 text-muted">Total Requirement:</p>
                                    <h5 class="text-success">{{ qty }}</h5>
                                    <!-- <p class="mb-0 text-muted">Total Budget:</p>
                                    <h5 class="text-success mb-0"></h5> -->
                                </article>
                            </div>
                            
                            <div class="col-sm-12 col-lg-4 col-xl-3">
                                <h6>Item Details</h6>
                                <p style="font-weight: 600;">
                                    Category : {{ category }} <br />
                                </p>
                            </div>

                            <div class="col-sm-12 col-lg-4 col-xl-6">
                                <h6 style="font-size: 13pt;">Area</h6>
                                <div style="display: flex;
                                            flex-direction: row;
                                            flex-wrap: wrap;
                                            width: 100%;
                                            gap : 15px">
                                    <span style="font-weight: 600;
                                                 text-align: center;
                                                 padding: 5px;
                                                 width: auto;
                                                 min-width: 80px;
                                                 background: var(--basecolor);
                                                 border-radius: 5px;
                                                 color: #fff;" 
                                       v-for="ar in area_name" :key="ar.area_kd">
                                        {{ ar.area_nm }}<br />
                                        {{ ar.qty }}<br />
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <section class="cd-products-comparison-table">
                    <div class="cd-products-table">
                        <div class="features">
                            <div class="top-info" 
                                 style="background: #696969;">
                                 Product
                            </div>

                            <ul class="cd-features-list">
                                <li>Nama Supplier</li>
                                <li>Merk</li>
                                <!-- <li>Remark</li> -->
                                <li>Description</li>
                                <li>Category</li>
                                <li>Area</li>
                                <li>Price</li>
                                <li>Discount</li>
                                <li>Discount Price</li>
                                <li>Final Price</li>
                            </ul>
                        </div> 

                        <div class="cd-products-wrapper" id="cd-products-wrapper">
                            <ul class="cd-products-columns" id="cd-products-columns">
                                <li class="product" v-for="item in penawaran" :key="item.penawaran_id" 
                                    :class="{'selected-product' : selected_product === item.kd_brg_supp}">
                                    <input type="radio" name="product"
                                               style="position: absolute;
                                                      top: 0;
                                                      left:0;
                                                      width: 100%;
                                                      height: 100%;
                                                      z-index: 10;
                                                      cursor: pointer;
                                                      opacity: 0;"
                                               :value="item.kd_brg_supp" 
                                               v-model="selected_product"
                                               @click="selectProduct(
                                                    item.request_id,
                                                    item.kd_brg,
                                                    item.kd_brg_supp,
                                                    item.sup_kd,
                                                    item.penawaran_id,
                                                    this.$store.getters.GET_AUTH_INFO.id,
                                               )">

                                    <div class="top-info position-relative">
                                        <img class="image-product-d" 
                                             :src="getProductImage(item.image_id)" 
                                             :alt="item.image_id"
                                             @click="showPreview(item.image_id)">
                                        <span class="title-text">{{ item.nm_brg }}</span>
                                    </div> 

                                    <ul class="cd-features-list">
                                        <li style="display: flex;align-items: center;justify-content: center;">{{ item.sup_nm }}</li>
                                        <li style="display: flex;align-items: center;justify-content: center;"></li>
                                        <!-- <li style="display: flex;align-items: center;justify-content: center;">{{ item.remark }}</li> -->
                                        <li style="display: flex;align-items: center;justify-content: center;">{{ item.desc_brg_supp }}</li>
                                        <li style="display: flex;align-items: center;justify-content: center;">{{ item.category }}</li>
                                        <li style="display: flex;align-items: center;justify-content: center;">
                                            <span v-for="ar in item.area" :key="ar.area_kd"
                                                  style="margin-left: 10px;">{{ ar.area_nm }} 
                                                  <span style="color: var(--bluecolor);"> ({{ ar.qty }})</span>, 
                                            </span>
                                        </li>
                                        <li style="display: flex;align-items: center;justify-content: center;color: var(--orange);font-weight: bold;">Rp. {{ formatPrice(item.hrg_satuan) }}</li>
                                        <li style="display: flex;align-items: center;justify-content: center;">{{ item.disc }}%</li>
                                        <li style="display: flex;align-items: center;justify-content: center; color: var(--tosca);font-weight: bold;">Rp. {{ formatPrice((item.hrg_satuan * item.disc) / 100) }}</li>
                                        <li style="display: flex;align-items: center;justify-content: center; color: var(--basecolor);font-weight: bold;">Rp. {{ formatPrice(item.hrg_satuan - ((item.hrg_satuan * item.disc) / 100)) }}</li>
                                    </ul>
                                    <div class="corner-penawaran" v-if="selected_product == item.kd_brg_supp">
                                        <i style="position: relative;top: -75px;right: 35px" class="fa-solid fa-star"></i>                                                
                                    </div> 
                                </li>

                                <div class="button-left" @click="scrollRight">
                                    <i class="fa-solid fa-angles-right"></i>
                                </div>

                                <div v-if="scroll > 100" class="button-right" @click="scrollLeft">
                                    <i class="fa-solid fa-angles-left"></i>
                                </div>
                            </ul> 
                        </div> 
                    </div> 
                </section> 
            </section>

            <Footer />
        </main>
    </div>

    <div>

    </div>

    <div v-if="showImage" class="image-preview">
        <div class="bg-overlay-screen" @click="closeImage"></div>
        <div class="image-holder fade-in-down">
            <img style="width: 100%;
                        height: 100%;
                        object-fit: contain;" 
                 :src="selectedImage" alt="">
        </div>
    </div>
</template>

<script>
import SuccessAlert from '@/components/SuccessAlert.vue'
import NavbarVue from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import PageTitle from '@/components/PageTitle.vue'
import Logo from '@/components/Logo.vue'
import axios from 'axios'

export default {
    name: 'PenawaranVue',
    components: {
        NavbarVue,
        Footer,
        PageTitle,
        Logo,
        SuccessAlert,
    },
    data(){
        return{
            scroll: 0,
            endScroll: 0,
            reqid: null,
            kdbrg: null,
            area: null,
            penawaran: null,
            nama_barang: null,
            description: null,
            description1: null,
            area_name: null,
            category: null,
            qty: 0,
            image: '',
            showImage: false,
            selectedImage: null,
            loading: false,
            selected: false,
            selected_product: null,
            pemenang: null,
            user_choosen: null,
            is_selected: false,
            product: {
                request_id: null,
                kd_brg: null,
                kd_brg_supp: null,
                supp_kd: null,
                penawaran_id: null,
                user_id: null,
            }
        }
    },
    mounted(){
        this.endScroll = document.getElementById('cd-products-columns').scrollWidth
        this.reqid = this.$route.params.reqid
        this.kdbrg = this.$route.params.kdbrg
        this.area = this.$route.params.area
        this.getPenawaran()
    },
    methods: {
        closeImage(){
            this.showImage = false
            this.selectedImage = null
        },
        showPreview(filename){
            this.showImage = true
            this.selectedImage = this.uri + '/image/' + filename
        },
        getProductImage(filename){
            return this.uri + '/image/' + filename
        },
        async getPenawaran(){
            this.loading = true;
            try {
                const { data } = await axios.get('/penawaran/' + this.reqid + '/' + this.kdbrg.substring(1) + '/' + this.area + '/' + this.$store.getters.GET_AUTH_TOKEN)
                this.penawaran = data.penawaran
                this.nama_barang = data.nm_brg
                this.description = data.desc_brg
                this.description1 = data.desc_brg2
                this.area_name = data.area
                this.category = data.category
                this.qty = data.qty
                this.image = data.image_id
                this.selected_product = data.kd_brg_supp
                this.user_choosen = data.user_select
                this.pemenang = data.pemenang

                console.log(this.selected_product)
                setTimeout(() => {this.loading = false}, 1000)
                console.log(data)
            } catch(e){
                const data = {
                    id: this.$store.getters.GET_AUTH_INFO.id,
                    access_token: this.$store.getters.GET_AUTH_TOKEN,
                }
                
                if(e.response.status == 401){
                    this.$store.dispatch("LOGOUT", data)
                    .then(() => {
                        window.location.href =  '/'
                    }).catch(() => {
                        window.location.href =  '/'
                    });
                }
            }
        },
        scrollRight(){
            document.getElementById('cd-products-columns').scrollLeft +=100;
            console.log(document.getElementById('cd-products-columns').offsetWidth)
            this.scroll = document.getElementById('cd-products-columns').scrollLeft
        },
        scrollLeft(){
            document.getElementById('cd-products-columns').scrollLeft -=100;
            console.log(document.getElementById('cd-products-columns').offsetWidth)
            this.scroll = document.getElementById('cd-products-columns').scrollLeft
        },
        formatPrice(value){
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        selectProduct(reqid, kdbrg, kdbrgsup, supkd, penwid, usrid){
            this.product.request_id = reqid;
            this.product.kd_brg = kdbrg;
            this.product.kd_brg_supp = kdbrgsup;
            this.product.supp_kd = supkd;
            this.product.penawaran_id = penwid;
            this.product.user_id = usrid;

            this.is_selected = true
            console.log(this.product)
        },
        async submitCart(){
            try{
                await axios.post('/cart/' + this.$store.getters.GET_AUTH_INFO.id + '/' + 
                    this.$store.getters.GET_AUTH_TOKEN, this.product
                , this.product);

                this.selected = true;
                setTimeout(() => {this.selected = false}, 1100);
            } catch(e){
                console.log(e)
            }
        }
    }
}
</script>

<style>
.image-preview{
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
}

.bg-overlay-screen{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: var(--overlay);
}

.image-holder{
    position: relative;
    top: 25%;
    margin: 0 auto;
    width: 500px;
    height: 500px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
}

.corner-penawaran{
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 80px solid var(--bluehover);
    border-left: 80px solid transparent;
    z-index: 12;
    color: var(--white);
    font-weight: 600;
    font-size: 18pt;
    opacity: 0.7;
}
</style>