<template>
    <header class="main-header navbar">
        <div class="col-search"></div>

        <div class="col-nav">
            <button class="btn btn-icon btn-mobile me-auto" 
                    data-trigger="#offcanvas_aside"
                    @click="showMenu" style="color: var(--bluecolor);">
                    <i class="material-icons md-apps"></i>
            </button>
            <ul class="nav">
                <!-- <li class="nav-item">
                    <a class="nav-link btn-icon" href="#">
                        <i class="material-icons md-notifications animation-shake"></i>
                        <span class="badge rounded-pill">3</span>
                    </a>
                </li>
                 -->
                <!-- <li class="nav-item">
                    <a class="nav-link btn-icon darkmode"> <i class="material-icons md-nights_stay"></i> </a>
                </li> -->
                <!-- <li class="nav-item">
                    <a href="#" class="requestfullscreen nav-link btn-icon"><i class="material-icons md-cast"></i></a>
                </li> -->
                <!-- <li class="dropdown nav-item">
                    <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" id="dropdownLanguage" aria-expanded="false"><i class="material-icons md-public"></i></a>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownLanguage">
                        <a class="dropdown-item text-brand" href="#"><img src="assets/imgs/theme/flag-us.png" alt="English" />English</a>
                        <a class="dropdown-item" href="#"><img src="assets/imgs/theme/flag-fr.png" alt="Français" />Français</a>
                        <a class="dropdown-item" href="#"><img src="assets/imgs/theme/flag-jp.png" alt="Français" />日本語</a>
                        <a class="dropdown-item" href="#"><img src="assets/imgs/theme/flag-cn.png" alt="Français" />中国人</a>
                    </div>
                </li> -->

                <li class="nav-item">
                    <router-link :to="{name: 'profile'}" class="nav-link">
                        <span>{{ name }}</span>
                    </router-link>
                </li>

                <li class="dropdown nav-item">
                    <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" id="dropdownAccount" aria-expanded="false"> 
                        <img class="img-xs rounded-circle" :src="getImage(image)" alt="User" />
                    </a>
                    
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownAccount">
                        <!-- <a class="dropdown-item" href="#"><i class="material-icons md-perm_identity"></i>Edit Profile</a>
                        <a class="dropdown-item" href="#"><i class="material-icons md-settings"></i>Account Settings</a>
                        <a class="dropdown-item" href="#"><i class="material-icons md-account_balance_wallet"></i>Wallet</a>
                        <a class="dropdown-item" href="#"><i class="material-icons md-receipt"></i>Billing</a>
                        <a class="dropdown-item" href="#"><i class="material-icons md-help_outline"></i>Help center</a>
                        <div class="dropdown-divider"></div> -->
                        
                        <div class="dropdown-item text-danger" style="cursor: pointer" @click="logout">
                            <i class="material-icons md-exit_to_app">
                            </i>Logout
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
export default {
    name: 'NavbarVue',
    data(){
        return {
            searchValue: null,
            supplier: null,
            name: null,
            image: null,
            userid: null,
            isMinimize: false,
        }
    },
    mounted(){
        this.getProfile()
    },
    methods: {
        getImage(filename){
            return this.uri + '/image-profile/' + filename
        },
        getProfile(){
            this.userid = this.$store.getters.GET_AUTH_INFO.id
            this.name = this.$store.getters.GET_AUTH_INFO.name
            this.image = this.$store.getters.GET_AUTH_INFO.image
        },
        logout(){
            if(this.is_proccess) return;
            this.is_proccess = true;

            const data = {
                id: String(this.userid),
                access_token: this.$store.getters.GET_AUTH_TOKEN,
            }
            
            this.$store.dispatch("LOGOUT", data)
            .then(() => {
                this.is_proccess = false
                window.location.href =  '/'
            }).catch(() => {
                this.is_proccess = false
                window.location.href =  '/'
            });
        },
        showMenu(){
            if(!this.isMinimize){
                this.isMinimize = true
                document.body.classList.add("offcanvas-active");
                document.getElementById("screen-overlay").classList.add("show")
                document.getElementById("offcanvas_aside").classList.add("show")
            } else {
                this.isMinimize = false
                document.body.classList.remove("offcanvas-active");
                document.getElementById("screen-overlay").classList.remove("show")
                document.getElementById("offcanvas_aside").classList.remove("show")
            }
        },  
    }
}
</script>

<style>

</style>