import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

const app = createApp(App)
axios.defaults.baseURL = 'https://139.0.5.108:8073/Supplier/web';
app.config.globalProperties.uri = 'https://139.0.5.108:8073/Supplier/web';
app.config.globalProperties.isHide = false;

app.use(store).use(router).mount('#app')
