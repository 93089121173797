<template>
    <div>
        <div class="screen-overlay"></div>
        <Logo />

        <main class="main-wrap">
            <Navbar />
            <section class="content-main">
                <div class="content-header">
                    <a href="javascript:history.back()" style="display: flex;align-items: center;">
                        <i class="material-icons md-arrow_back"></i>
                        <span style="font-size: 13pt;font-weight: bold;"> Back</span>
                    </a>
                </div>

                <div class="content-header">
                    <h2 class="content-title">Profile setting</h2>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row gx-5">
                            <div class="col-lg-12">
                                <section class="content-body p-xl-4">
                                    <form>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <div class="row gx-3">
                                                    <div class="col-6 mb-3">
                                                        <label class="form-label">User ID</label>
                                                        <input class="form-control" type="text" v-model="userid" readonly />
                                                    </div>
                                                    
                                                    <div class="col-6 mb-3">
                                                        <label class="form-label">Name</label>
                                                        <input class="form-control" type="text" v-model="name" readonly/>
                                                    </div>
                                                    
                                                    <div class="col-lg-6 mb-3">
                                                        <label class="form-label">Email</label>
                                                        <input class="form-control" type="email" placeholder=""  readonly/>
                                                    </div>
                                                    
                                                    <div class="col-lg-6 mb-3">
                                                        <label class="form-label">Phone</label>
                                                        <input class="form-control" type="tel" readonly />
                                                    </div>
                                                    
                                                    <div class="col-lg-12 mb-3">
                                                        <label class="form-label">Division</label>
                                                        <input class="form-control" type="text" readonly/>
                                                    </div>
                                                    
                                                    <div class="col-lg-6 mb-3">
                                                        <label class="form-label">Subdivision</label>
                                                        <input class="form-control" type="text"  readonly/>
                                                    </div>

                                                    <div class="col-lg-6 mb-3">
                                                        <label class="form-label">Department</label>
                                                        <input class="form-control" type="text"  readonly/>
                                                    </div>

                                                </div>
                                            </div>

                                            <aside class="col-lg-4">
                                                <figure class="text-lg-center">
                                                    <img class="img-lg mb-3 img-avatar" :src="getImage(image)" alt="User Photo" />

                                                    <figcaption>
                                                        <a class="btn btn-light rounded font-md" href="#" @click="toggleShow"> 
                                                            <i class="icons material-icons md-backup font-md"></i> 
                                                            Upload 
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </aside>
                                        </div>
                                        
                                        <br />
                                        <button class="btn btn-primary" type="submit">
                                            <i class="fa-solid fa-floppy-disk"
                                               style="margin-top: 1px;"></i>
                                            Save changes
                                        </button>
                                    </form>
                                    <hr class="my-5" />
                                    <div class="row" style="max-width: 920px">
                                        <div class="col-md">
                                            <article class="box mb-3 bg-light">
                                                <a class="btn float-end btn-light btn-sm rounded font-md" 
                                                   @click="changePassword">
                                                   {{ changetext }}
                                                </a>
                                                <h6>Password</h6>
                                                <small class="text-muted d-block" style="width: 70%">You can reset or change your password by clicking here</small>
                                            </article>
                                            <div style="position: relative;
                                                        width: 100%;
                                                        display: flex;
                                                        flex-direction: row;
                                                        flex-wrap: wrap;
                                                        justify-content: space-between;"
                                                        v-if="isChange">
                                                <div style="position: relative;width: 48%;margin-bottom: 40px;">
                                                    <label for="oldpasword">Old Password</label>
                                                    <input name="oldpassword" id="oldpassword" type="password" class="form-control">
                                                    <div class="show-password-profile" @click="showOldPassword">
                                                        <i v-if="showOld" class="fa-solid fa-eye"></i>
                                                        <i v-if="!showOld" class="fa-solid fa-eye-slash"></i>
                                                    </div>
                                                </div>

                                                <div style="position: relative;width: 48%;margin-bottom: 40px;">
                                                    <label for="newpassword">New Password</label>
                                                    <input name="newpassword" id="newpassword" type="password" class="form-control">
                                                    <div class="show-password-profile" @click="showNewPassword">
                                                        <i v-if="showNew" class="fa-solid fa-eye"></i>
                                                        <i v-if="!showNew" class="fa-solid fa-eye-slash"></i>
                                                    </div>
                                                </div>

                                                <button style="position: absolute;bottom: -20px;right: 0;"
                                                        class="btn btn-primary">
                                                        <i class="fa-solid fa-floppy-disk"
                                                           style="margin-top: 1px;"></i>
                                                        Save Changes
                                                </button>
                                            </div>
                                        </div>                                    
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </main>
    </div>

    <div>
        <my-upload field="img"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            v-model="show"
            :width="300"
            :height="300"
            url="/upload"
            :params="params"
            :headers="headers"
            :langType="en"
            img-format="png">
        </my-upload>
        <img :src="imgDataUrl">
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import myUpload from 'vue-image-crop-upload';

export default {
    name: 'ProfileView',
    components: {
        Logo,
        Navbar,
        Footer,
        'my-upload': myUpload
    },
    data(){
        return {
            userid: null,
            name: null,
            image: null,
            isChange: false,
            changetext: 'Change',
            showOld: false,
            showNew: false,
            show: false,
			params: {
				token: '123456798',
				name: 'avatar'
			},
			headers: {
				smail: '*_~'
			},
			imgDataUrl: '',
            en: {
                hint: 'Click or drag the file here to upload',
                loading: 'Uploading…',
                noSupported: 'Browser is not supported, please use IE10+ or other browsers',
                success: 'Upload success',
                fail: 'Upload failed',
                preview: 'Preview',
                btn: {
                    off: 'Cancel',
                    close: 'Close',
                    back: 'Back',
                    save: 'Save'
                },
                error: {
                    onlyImg: 'Image only',
                    outOfSize: 'Image exceeds size limit: ',
                    lowestPx: 'Image\'s size is too low. Expected at least: '
                }
            }
        }
    },
    mounted(){
        this.getProfile()
    },
    methods: {
        getImage(filename){
            return this.uri + '/image-profile/' + filename
        },
        getProfile(){
            this.userid = this.$store.getters.GET_AUTH_INFO.id
            this.name = this.$store.getters.GET_AUTH_INFO.name
            this.image = this.$store.getters.GET_AUTH_INFO.image
        },
        changePassword(){
            if(this.isChange) {
                this.isChange = false
                this.changetext = 'Change'
            } else {
                this.isChange = true
                this.changetext = 'Cancel'
            }
        },
        showOldPassword(){
            if(this.showOld){
                document.getElementById('oldpassword').type = 'password'
                this.showOld = false
            } else {
                document.getElementById('oldpassword').type = 'text'
                this.showOld = true
            }
        },
        showNewPassword(){
            if(this.showNew){
                document.getElementById('newpassword').type = 'password'
                this.showNew = false
            } else {
                document.getElementById('newpassword').type = 'text'
                this.showNew = true
            }
        },
        toggleShow() {
            this.show = !this.show;
        },
        cropSuccess(imgDataUrl, field){
            console.log('-------- crop success --------');
            this.imgDataUrl = imgDataUrl;
            console.log(field)
        },
        cropUploadSuccess(jsonData, field){
            console.log('-------- upload success --------');
            console.log(jsonData);
            console.log('field: ' + field);
        },
        cropUploadFail(status, field){
            console.log('-------- upload fail --------');
            console.log(status);
            console.log('field: ' + field);
        }
    }
}
</script>

<style>
.show-password-profile{
    position: absolute;
    right: 10px;
    top: 30px;
    font-size: 13pt;
    cursor: pointer;
    color: var(--graybold);
}
</style>