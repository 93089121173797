<template>
    <success-alert v-if="success" 
            :message="messages">
    </success-alert>

    <div v-if="loading" class="img-loader"></div>

    <div v-if="!loading">
        <div class="screen-overlay" id="screen-overlay"></div>
        <Logo />

        <main class="main-wrap">
            <NavbarVue />

            <section class="content-main">
                <div class="content-header">
                    <a href="javascript:history.back()" style="display: flex;align-items: center;">
                        <i class="material-icons md-arrow_back"></i>
                        <span style="font-size: 13pt;font-weight: bold;"> Back</span>
                    </a>
                </div>

                <PageTitle title="Review" desc="Items list to be purchased." :isShow="showbtn"/>

                <div class="card mb-4" v-if="review.length <= 0">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td colspan="8" 
                                            style="text-align: center;
                                            margin-top: 10px;
                                            font-size: 12pt;
                                            font-weight: bold;">
                                            No Data
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card mb-4" v-for="pr in review" :key="pr.pr_no">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody style="border-bottom: 1px solid #e4e4e4;">
                                    <tr>
                                        <td colspan="2">
                                            <img style="width: 200px;" src="/assets/imgs/logo/movenpick_352.png" alt="">
                                        </td>
                                        <td colspan="6" class="text-end">
                                            <a class="btn btn-danger rounded font-md"
                                                style="margin-right: 10px;"
                                                @click="createPO(pr.pr_no)"
                                                v-if="pr.po_no === 0 && pr.decline === 0">
                                                <i class="fa-solid fa-file-circle-check" 
                                                   style="margin-top: 2px;">
                                                </i>
                                                <span>Create PO</span>
                                            </a>

                                            <span v-if="pr.po_no !== 0">PO Number :</span>
                                            <span v-if="pr.po_no !== 0" 
                                                  style="font-size: 20pt;
                                                  font-weight: bold;
                                                  margin-left: 15px;">
                                                  {{ pr.po_no }}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">Vendor Name</td>
                                        <td colspan="2" 
                                        style="font-weight: bold;
                                               font-size: 11pt;">
                                            <span>:</span> 
                                            <span style="margin-left: 10px;">{{ pr.sup_kd }}</span>
                                        </td>

                                        <td colspan="2">Jenis PO</td>
                                        <td colspan="2">
                                            <select v-if="pr.po_no === 0" :id="'jenis' + pr.pr_no"
                                                    class="form-control">
                                                    <option value="asset">Asset</option>
                                                    <option value="expense">Expense</option>
                                            </select>

                                            <span style="margin-left: 10px;" v-if="pr.po_no !== 0">{{ pr.jenis_po }}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">Address</td>
                                        <td colspan="2">
                                            <span>:</span> 
                                            <span style="margin-left: 5px;"></span>
                                        </td>
                                        <td colspan="2">Requested By</td>
                                        <td colspan="2">
                                            <span>:</span> 
                                            <span style="margin-left: 5px;">{{ this.$store.getters.GET_AUTH_INFO.name }}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">Telephone</td>
                                        <td colspan="2">
                                               :
                                        </td>
                                        <td colspan="2">Department</td>
                                        <td colspan="2">
                                               :
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">Description</td>
                                        <td colspan="6">
                                            <textarea v-if="pr.po_no === 0" rows="3"
                                            style="width: 100%;resize: none;"
                                            class="form-control" :id="'desc-' + pr.pr_no"></textarea>

                                            <span v-if="pr.po_no !== 0">:</span>
                                            <span v-if="pr.po_no !== 0" style="margin-left: 10px;">{{ pr.desc }}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">Expected Date</td>
                                        <td colspan="2">
                                            <input type="date" :id="'xtd-' + pr.pr_no" 
                                            class="form-control" v-if="pr.po_no === 0">
                                            <span v-if="pr.po_no === 0 && error_xtd" style="color: var(--basecolor)">{{ error_xtd }}</span>

                                            <span v-if="pr.po_no !== 0">:</span>
                                            <span v-if="pr.po_no !== 0" style="margin-left: 10px;">{{ pr.expected }}</span>
                                        </td>
                                        <td colspan="2">Expired Date</td>
                                        <td colspan="2">
                                            <input type="date" :id="'exp-' + pr.pr_no" 
                                            class="form-control" v-if="pr.po_no === 0">
                                            <span v-if="pr.po_no === 0 && error_exp" style="color: var(--basecolor)">{{ error_exp }}</span>

                                            <span v-if="pr.po_no !== 0">:</span>
                                            <span v-if="pr.po_no !== 0" style="margin-left: 10px;">{{ pr.expired }}</span>
                                        </td>
                                        
                                    </tr>
                                    
                                    <tr style="outline: thin solid">
                                        <th>Product ID</th>
                                        <th scope="col">Image</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Total</th>
                                        <th scope="col" class="text-end">Price</th>
                                        <th scope="col">UserID</th>
                                        <th scope="col" class="text-center"
                                            v-if="pr.po_no === 0">Action</th>
                                        <th scope="col" class="text-center"
                                            v-if="pr.po_no !== 0">Jenis PO</th>
                                    </tr>

                                    <tr v-for="item in pr.products" :key="item.kd_brg_supp"
                                        :class="{'bg-decline': item.status === 'decline'}">
                                        <td>{{ item.kd_brg_supp }}</td>
                                        <td style="width:100px">
                                            <div style="width: 70px;
                                                        height: 70px;
                                                        border-radius: 5px;
                                                        background: var(--white);">
                                                 <img style="width: 100%;object-fit: contain;" 
                                                     :src="getProductImage(item.image_id)" alt="">
                                            </div>
                                        </td>
                                        <td style="font-weight: bold;">
                                            <a>
                                                <b>{{ item.nm_brg }}</b>
                                            </a>
                                        </td>
                                        <td>{{ item.category }}</td>
                                        <td class="text-center">{{ item.qty }}</td>
                                        <td class="text-end">Rp. {{ formatPrice(item.harga) }}</td>
                                        <td>{{ item.user_id }}</td>
                                        <td class="text-center">
                                            <a class="btn btn-md rounded font-sm"
                                               @click="declineItem(item.cart_id)"
                                               v-if="item.status !== 'decline' && pr.po_no === 0">
                                               Decline
                                            </a>

                                            <span v-if="pr.po_no !== 0">{{ pr.jenis_po }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
<!-- 
                <div class="pagination-area mt-15 mb-50">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-start">
                            <li class="page-item active"><a class="page-link" href="#">01</a></li>
                            <li class="page-item"><a class="page-link" href="#">02</a></li>
                            <li class="page-item"><a class="page-link" href="#">03</a></li>
                            <li class="page-item"><a class="page-link dot" href="#">...</a></li>
                            <li class="page-item"><a class="page-link" href="#">16</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#"><i class="material-icons md-chevron_right"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div> -->
            </section>
           
            <Footer />
        </main>
    </div>

    <div v-if="show_modal" class="alert-modal">
        <div class="screen-overlays" @click="show_modal = false"></div>
        <div class="modal-card shake-r">
            <span style="font-size: 18pt;">Are You Sure ?</span>

            <div style="position: relative;
                        width: 90%;
                        display: flex;
                        flex-direction: column;
                        margin: 0 auto;
                        margin-top: 10px;
                        font-size: 12pt;">
                <span>Pelase Enter Cancellation Reason</span>
                <textarea style="resize: none;border-radius: 5px;" 
                    v-model="cancel_reason" cols="30" rows="5">
                </textarea>
            </div>

            <div class="modal-btn">
                <button class="btn btn-warning text-white"
                        @click="confirmCancel">Yes, Cancel it!
                </button>

                <button class="btn btn-danger"
                        @click="show_modal = false">Cancel
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import SuccessAlert from '@/components/SuccessAlert.vue'
import NavbarVue from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import PageTitle from '@/components/PageTitle.vue'
import Logo from '@/components/Logo.vue'
import axios from 'axios'

export default {
    name: 'ReviewCart',
    components: {
        NavbarVue,
        Footer,
        PageTitle,
        Logo,
        SuccessAlert,
    },
    data(){
        return {
            review: [],
            cancel_reason: null,
            show_modal: false,
            cartid: null,
            success: false,
            showbtn: false,
            messages: null,
            loading: true,
            error_xtd: null,
            error_exp: null,
        }
    },
    mounted(){
        this.getReview()
    },
    methods: {
        async getReview(){
            try {
                const { data } = await axios.get('/review/' + 
                                this.$store.getters.GET_AUTH_INFO.id + 
                                '/' + this.$store.getters.GET_AUTH_TOKEN
                )

                this.review = data
                if(this.review.length > 0) {
                    this.showbtn = true
                } else {
                    this.showbtn = false
                }

                console.log(this.review)
                setTimeout(() => {
                    this.loading = false
                }, 1200)
            } catch(e){
                console.log(e)
            }
        },
        getProductImage(filename){
            return this.uri + '/image/' + filename
        },
        declineItem(cartid){
            this.cartid = cartid
            this.show_modal = true
        },
        async confirmCancel(){
            try {
                await axios.delete('/review/' + this.$store.getters.GET_AUTH_INFO.id
                    + '/' + this.$store.getters.GET_AUTH_TOKEN, { data: {
                        cart_id: this.cartid,
                        cancel_reason: this.cancel_reason,
                    }}
                )

                this.show_modal = false
                this.messages = 'Item Canceled!'
                this.success = true
                this.cancel_reason = null
                this.getReview()
                setTimeout(() => {
                    this.success = false
                    this.messages = null
                }, 1000)
            } catch(e){
                console.log(e)
            }
        },
        formatPrice(value){
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        async createPO(prid){
            try {
                const xtd = document.getElementById('xtd-' + prid).value
                const exp = document.getElementById('exp-' + prid).value
                const desc = document.getElementById('desc-' + prid).value
                const jenis = document.getElementById('jenis' + prid).value

                if(!xtd || !exp || !jenis) {
                    if(!xtd) this.error_xtd = "Expected Date field is required!"
                    if(!exp) this.error_exp = "Expired Date field is required!"

                    setTimeout(() => {
                        this.error_xtd = null
                        this.error_exp = null
                    }, 3000);
                    return;
                }
 
                const body = {
                    pr_no: prid,
                    jenis_po: jenis,
                    description: desc,
                    expected_date: xtd,
                    expired_date: exp
                }

                const { data } = await axios.post('/po/' + this.$store.getters.GET_AUTH_INFO.id + '/' +
                this.$store.getters.GET_AUTH_TOKEN, body)

                console.log(data)
                this.messages = 'New PO Created!'
                this.success = true
                this.getReview()
                setTimeout(() => {
                    this.success = false
                    this.messages = null
                }, 1000);
            } catch(e){
                console.log(e)
            }
        }
    }
}
</script>

<style>

</style>