<template>
    <nav>
        <ul class="menu-aside">
            <li class="menu-item" :class="{'active': this.$route.name === 'home'}">
                <router-link class="menu-link" :to="{name: 'home'}">
                    <!-- <i class="icon material-icons md-home"></i> -->
                    <i class="icon material-icons md-home"></i>
                    <span class="text">Dashboard</span>
                </router-link>
            </li>

            <li class="menu-item" :class="{'active': this.$route.name === 'upload'}">
                <router-link class="menu-link" :to="{name: 'upload'}">
                    <i class="icon fa-solid fa-upload" 
                       style="font-size: 13pt;margin-left: 3px;"></i>
                    <span class="text" style="margin-left: 3px;">Upload</span>
                </router-link>
            </li>

            <li class="menu-item" :class="{'active': this.$route.name === 'cartitem'}">
                <router-link class="menu-link" :to="{name: 'cartitem'}">
                    <i class="icon material-icons md-shopping_bag"></i>
                    <span class="text">Cart</span>
                </router-link>
            </li>

            <li class="menu-item" :class="{'active': this.$route.name === 'review'}"
                v-if="userlevel == 999">
                <router-link class="menu-link" :to="{name: 'review'}">
                    <i class="icon material-icons md-comment"></i>
                    <span class="text">Review</span>
                </router-link>
            </li>
            
            <li class="menu-item" :class="{'active': this.$route.name === 'orderlist'}"
                v-if="userlevel == 999">
                <router-link class="menu-link" :to="{name: 'orderlist'}">
                    <i class="icon material-icons md-monetization_on"></i>
                    <span class="text">Transaction</span>
                </router-link>
            </li>
            
            <!-- <li class="menu-item has-submenu">
                <a class="menu-link" href="page-products-list.html">
                    <i class="icon material-icons md-shopping_bag"></i>
                    <span class="text">Products</span>
                </a>
                <div class="submenu">
                    <a href="page-products-list.html">Product List</a>
                    <a href="page-products-grid.html">Product grid</a>
                    <a href="page-products-grid-2.html">Product grid 2</a>
                    <a href="page-categories.html">Categories</a>
                </div>
            </li> -->
        </ul>

        <!-- <hr />
        <ul class="menu-aside">
            <li class="menu-item has-submenu">
                <a class="menu-link" href="#">
                    <i class="icon material-icons md-settings"></i>
                    <span class="text">Settings</span>
                </a>
                <div class="submenu">
                    <a href="page-settings-1.html">Setting sample 1</a>
                    <a href="page-settings-2.html">Setting sample 2</a>
                </div>
            </li>
            <li class="menu-item">
                <a class="menu-link" href="page-blank.html">
                    <i class="icon material-icons md-local_offer"></i>
                    <span class="text"> Starter page </span>
                </a>
            </li>
        </ul> -->
    </nav>
</template>

<script>
export default {
    name: 'SidebarVue',
    data(){
        return{
            userlevel: null,
        }
    },
    mounted(){
        this.getUserInfo()
    },
    methods: {
        getUserInfo(){
            this.userlevel = this.$store.getters.GET_AUTH_INFO.level
        }
    }
}
</script>

<style>

</style>