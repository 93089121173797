import axios from 'axios';

export const auth = {
    state: {
        auth_status: false,
        auth_token: null,
        auth_info: {
            id: null,
            name: null,
            image: null,
            level: null,
        },
    },
    
    getters: {
        GET_AUTH_TOKEN(state){
            return state.auth_token;
        },

        GET_AUTH_INFO(state){
            return state.auth_info;
        },
    },
    
    actions: {
        LOGIN(context, loginData){
            return new Promise ((resolve, reject) => {
                axios.post('/login', loginData)
                     .then((response) => {
                        context.commit('SET_AUTH_TOKEN', response.data.token);
                        context.commit('SET_AUTH_INFO', response.data.user);
                        resolve(response);
                     }).catch((error) => {
                        reject(error);
                     })
            });
        },     
        
        LOGOUT(context, logoutData){
            axios.defaults.headers.common['Authorization'] = context.state.auth_token;
            return new Promise ((resolve, reject) => {
                axios.post('/logout', logoutData)
                     .then((response) => {
                        context.commit('SET_AUTH_LOGOUT')
                        resolve(response);
                     }).catch((error) => {
                        context.commit('SET_AUTH_LOGOUT')
                        reject(error);
                     })
            });
        }
    },

    mutations: {
        SET_AUTH_TOKEN(state, token){
            state.auth_token = token;
        },

        SET_AUTH_INFO(state, info){
            state.auth_info.id = info.userid;
            state.auth_info.name = info.nama;
            state.auth_info.image = info.image;
            state.auth_info.level = info.level;
        },

        SET_AUTH_LOGOUT(state){
            state.auth_token = null;
            state.ref_token = null;
            state.auth_info = {
                    id: null,
                    name: null,
                    image: null,
                    level: null,
            }
        }
    }
}