<template>
    <div class="content-header">
        <div>
            <h2 class="content-title card-title">
                {{ title }}
            </h2>
            <p>{{ desc }}</p>
        </div>
        <div>
            <!-- <a href="#" class="btn btn-light rounded font-md">Export</a> -->
            <button class="btn btn-danger rounded font-md" @click="submitCart"
                style="margin-right: 10px;" v-if="this.$route.name === 'cartitem' && isShow">
                <i class="fa-solid fa-paper-plane"></i>
                <span>Submit</span>
            </button>

            <button class="btn btn-danger rounded font-md"
                style="margin-right: 10px;" v-if="this.$route.name === 'upload' && isShow">
                <i class="fa-solid fa-paper-plane"></i>
                <span>Upload</span>
            </button>

            <a class="btn btn-primary btn-sm rounded" 
               @click="reload" id="refresh-btn">Refresh</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'PageTitle',
    props: ['title', 'desc', 'isShow'],
    methods: {
        reload(){
            window.location.reload()
        },
        async submitCart(){
            try {
                const id = this.$store.getters.GET_AUTH_INFO.id
                const { data } = await axios.post('/review/' + id + '/' +
                    this.$store.getters.GET_AUTH_TOKEN, { data: { user_id: id}}
                )

                this.$emit('submitted', true).
                console.log(data)
            } catch(e){
                console.log(e)
            }
        }
    }
}
</script>

<style>

</style>