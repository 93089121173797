<template>
  <div v-if="loading" class="img-loader"></div>
  <div v-if="!loading">
    <div class="screen-overlay" id="screen-overlay"></div>
    <Logo />

    <main class="main-wrap">
        <NavbarVue />
        <section class="content-main">
            <PageTitle title="Request List" desc="List of all procurement request." />

            <div class="row">
                <div class="col-lg-3">
                    <div class="card card-body mb-4">
                        <article class="icontext">
                            <span class="icon icon-sm rounded-circle bg-primary-light">
                                <i class="text-success fa-solid fa-file-lines"></i>
                            </span>
                            <div class="text">
                                <h6 class="mb-1 card-title">Request</h6>
                                <span>{{ total_request }}</span>
                                <span class="text-sm">Number of all requests.</span>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card card-body mb-4">
                        <article class="icontext">
                            <span class="icon icon-sm rounded-circle bg-info-light">
                                <i class="text-info fa-solid fa-rectangle-list"></i>
                            </span>
                            <div class="text">
                                <h6 class="mb-1 card-title">Categories</h6>
                                <span>{{ total_category }}</span>
                                <span class="text-sm">Total product category.</span>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card card-body mb-4">
                        <article class="icontext">
                            <span class="icon icon-sm rounded-circle bg-warning-light">
                                <i class="text-warning fa-solid fa-boxes-packing"></i>
                            </span>
                            <div class="text">
                                <h6 class="mb-1 card-title">Req in Category</h6>
                                <span>{{ total_penawaran }}</span>
                                <span class="text-sm">Request per category</span>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card card-body mb-4">
                        <article class="icontext">
                            <span class="icon icon-sm rounded-circle bg-primary-light">
                                <i class="text-secondary fa-solid fa-industry"></i>
                            </span>
                            <div class="text">
                                <h6 class="mb-1 card-title">Suppliers</h6>
                                <span>{{ total_supplier }}</span>
                                <span class="text-sm">Number of Suppliers.</span>
                            </div>
                        </article>
                    </div>
                </div>
            </div>

            <div class="card card-body mb-4">
                <div class="row gx-3" style="gap: 10px;">
                    <div class="col-lg-4 col-md-6 me-auto">                        
                        <div class="searchform" v-if="this.$route.name === 'home'">
                            <div class="input-group">
                                <input list="search_terms" type="text" 
                                    class="form-control" placeholder="Search Item" 
                                    v-model="searchValue" @keypress.enter="searchItem"/>
                                <button class="btn btn-light bg" type="button"
                                        @click="searchItem">
                                    <i class="material-icons md-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <select class="form-select" v-model="selected_category" 
                                @change="getRequestCategory">
                            <option value="*">All category</option>
                            <option v-for="ctg in category" :key="ctg.name" 
                                    :value="ctg.name">{{ ctg.name }}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="col-lg-2 col-5 col-md-3">
                        <select class="form-select" v-model="itemPerPage">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </div> -->
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-body">
                    <div class="itemlist" style="width: 103%;">
                        <div class="col-lg-12 row align-items-center" v-for="items in request[current_page]" :key="items.id">
                            <div class="col-lg-12 row align-items-center">
                                <div class="request-wrapper" :class="{'request-active' : items.pemenang !== ''}">
                                    <div class="corner-request" v-if="items.pemenang">
                                    </div>

                                    <div class="request-image">
                                        <img class="req-image" :src="getProductImage(items.image_id)" :alt="items.image_id">
                                    </div>

                                    <div class="request-details">
                                        <router-link :to="{name: 'penawaran', params: {reqid: items.request_id, 
                                                            kdbrg: '1' + String(items.kd_brg), 
                                                            area: items.area_kd}}" 
                                                    style="font-size: 12pt;font-weight: 600;color: #545454;font-size: 13pt;">
                                                    {{items.nm_brg}}
                                        </router-link>
                                        <span>{{ items.desc_brg }}</span>   
                                        <span style="color: var(--bluecolor);">{{ items.desc_brg2 }}</span>   
                                    </div>

                                    <div class="request-area">
                                        <span style="color: var(--orange);">{{ items.category }}</span>
                                    </div>

                                    <div class="request-button">
                                        <router-link :to="{name: 'penawaran', 
                                                        params: {
                                                            reqid: items.request_id, 
                                                            kdbrg: '1' + String(items.kd_brg), 
                                                            area: items.area_kd
                                                    }}" 
                                                    class="btn btn-sm font-sm rounded btn-brand">
                                            <i class="material-icons md-launch"></i> Details 
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <span v-if="supplier.length == 0" 
                          style="position: relative;
                                 margin: 0 auto;
                                 font-size: 13pt;">
                          No item found!
                    </span>
                </div>
            
            </div>
            
            <div class="pagination-area mt-30 mb-50">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-start">
                        <li class="page-item" v-if="start_slice >= 5"
                            @click="prevPagination">
                            <a class="page-link">
                                <i class="material-icons md-chevron_left"
                                   style="margin-top: 3px;font-size: 20pt;
                                          margin-left: -4px;"></i>
                            </a>
                        </li>

                        <li class="page-item" :class="{'active' : current_page == pg}"
                            v-for="pg in total_page.slice(start_slice, end_slice)" :key="pg"
                            @click="current_page = pg">
                            <a class="page-link">{{ pg + 1 }}</a>
                        </li>

                        <li class="page-item" v-if="end_slice < total_page.length"
                            @click="nextPagination">
                            <a class="page-link">
                                <i class="material-icons md-chevron_right"
                                   style="margin-top: 3px;font-size: 20pt;
                                          margin-left: -4px;"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>

        <Footer />
    </main>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import NavbarVue from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import PageTitle from '@/components/PageTitle.vue'
import axios from 'axios'
export default {
  name: 'HomeView',
  components: {
    Logo,
    NavbarVue,
    Footer,
    PageTitle,
  },
  data(){
    return{
      supplier: [],
      request: [],
      loading: false,
      searchValue: '',
      total_request: 0,
      total_category: 0,
      total_penawaran: 0,
      total_supplier: 0,
      current_page: 0,
      total_page: [],
      itemPerPage: 10,
      start_slice: 0,
      end_slice: 10,
      category: [],
      selected_category: '*',
    }
  },
  watch: {
        searchValue: function(){
            if(this.searchValue == ''){
                this.searchItem()        
            }
        },
        itemPerPage: function(){
            this.getPaginate()
        },
  },
  mounted(){
    this.getRequest()
  },
  methods: {
    prevPagination(){
        if(this.start_slice <= 0) return;

        this.start_slice -= 5
        this.end_slice -= 5
    },
    nextPagination(){
        if(this.end_slice > this.total_page.length) {
            this.start_slice = this.total_page.length - 5
            this.end_slice = this.total_page.length
            return;
        }

        this.start_slice += 5
        this.end_slice += 5
    },
    getPaginate(){
        const groupSize = this.itemPerPage;

        this.request = []
        this.total_page = []
        const newSupplier = [];
        this.supplier.forEach((data) => {
            data.items.forEach((el) => {
                newSupplier.push(el)
            })
        })        

        for (var j = 0; j < newSupplier.length; j += groupSize) {
            this.request.push(newSupplier.slice(j, j + groupSize));
        }

        for(var i = 0; i < this.request.length; i++){
            this.total_page.push(i)
        }
    },
    async getRequestCategory(){
        try{
            this.request = [];
            this.total_page = [];

            const { data } = await axios.get('/request/'+ this.selected_category + '/' + this.$store.getters.GET_AUTH_TOKEN);
            this.total_request = data.all_request
            this.total_penawaran = data.total_request
            this.total_supplier = data.total_supplier
            this.total_category = data.total_category
            this.supplier = data.request
            this.category = data.tujuan

            const groupSize = this.itemPerPage;
            const newSupplier = [];
            this.supplier.forEach((data) => {
                data.items.forEach((el) => {
                    newSupplier.push(el)
                })
            })

            for (let i = 0; i < newSupplier.length; i += groupSize) {
                this.request.push(newSupplier.slice(i, i + groupSize));
            }
            
            for(var i = 0; i < this.request.length; i++){
                this.total_page.push(i)
            }
        } catch(e){
          const data = {
            id: this.$store.getters.GET_AUTH_INFO.id,
            access_token: this.$store.getters.GET_AUTH_TOKEN,
          }
          
          if(e.response.status == 401){
            this.$store.dispatch("LOGOUT", data)
            .then(() => {
                window.location.href =  '/'
            }).catch(() => {
                window.location.href =  '/'
            });
          }
        }
    },
    async getRequest(){
        this.loading = true
        try{
            this.request = [];
            this.total_page = [];

            const { data } = await axios.get('/request/'+ this.selected_category + '/' + this.$store.getters.GET_AUTH_TOKEN);
            this.total_request = data.all_request
            this.total_penawaran = data.total_request
            this.total_supplier = data.total_supplier
            this.total_category = data.total_category
            this.supplier = data.request
            this.category = data.tujuan

            console.log(data)
            const groupSize = this.itemPerPage;
            const newSupplier = [];
            this.supplier.forEach((data) => {
                data.items.forEach((el) => {
                    newSupplier.push(el)
                })
            })

            for (let i = 0; i < newSupplier.length; i += groupSize) {
                this.request.push(newSupplier.slice(i, i + groupSize));
            }
            
            for(var i = 0; i < this.request.length; i++){
                this.total_page.push(i)
            }

            setTimeout(() => {this.loading = false}, 1200)
        } catch(e){
          const data = {
            id: this.$store.getters.GET_AUTH_INFO.id,
            access_token: this.$store.getters.GET_AUTH_TOKEN,
          }
          
          if(e.response.status == 401){
            this.$store.dispatch("LOGOUT", data)
            .then(() => {
                window.location.href =  '/'
            }).catch(() => {
                window.location.href =  '/'
            });
          }
        }
    },
    async searchItem(){
        try {
            this.request = [];
            this.total_page = []
            const { data } = await axios.get('/search/*' + this.searchValue + '*/' + this.$store.getters.GET_AUTH_TOKEN);
            console.log(data)

            this.supplier = data
            const groupSize = this.itemPerPage;
            const newSupplier = [];
            this.supplier.forEach((data) => {
                data.items.forEach((el) => {
                    newSupplier.push(el)
                })
            })

            for (let i = 0; i < newSupplier.length; i += groupSize) {
                this.request.push(newSupplier.slice(i, i + groupSize));
            }
            
            for(var i = 0; i < this.request.length; i++){
                this.total_page.push(i)
            }
        } catch(e){
            console.log(e)
        }
    },
    getProductImage(filename){
        return this.uri + '/image/' + filename
    },
  }
}
</script>

<style>
.request-wrapper{
    position: relative;
    width: 100%;
    height: auto;
    min-height: 80px;
    background: #f4f4f4;
    border-radius: 3px;
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    margin-top: 10px;
}

.request-active{
    background: var(--bluecanvas);
    border: 2px solid var(--bluecolor);
}

.request-image{
    position: relative;
    width: 70px;
    height:80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.request-image .req-image{
    width: 100%;
    height: 80%;
    background: var(--white);
    border-radius: 4px;
    transition: .5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff
}

.request-image .req-image:hover{
    transform: scale(1.2);
}

.request-details{
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 10px;
    line-height: 20px;
    font-size: 11pt;
}

.request-area{
    position: relative;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.request-button{
    position: absolute;
    right: 0;
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.corner-request{
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 30px solid var(--bluecolor);
    border-right: 30px solid transparent;
    z-index: 12;
    color: var(--white);
    font-weight: 600;
    font-size: 18pt;
    opacity: 0.7;
}
</style>
