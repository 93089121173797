import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PenawaranView from '../views/Penawaran.vue'
import LoginView from '@/views/LoginView.vue'
import ProfileView from '@/views/ProfileView.vue'
import CartItemView from '@/views/CartItem.vue'
import OrderListView from '@/views/OrderListView.vue'
import ReviewCart from '@/views/ReviewCart.vue'
import UploadPenawaranView from '@/views/UploadPenawaranView.vue'
import OrderDetailView from '@/views/OrderDetailView.vue'
import store from '../store'

const routes = [
  {path: '/', name: 'home', component: HomeView, meta: {login: true}},
  {path: '/penawaran/:reqid/:kdbrg/:area', name: 'penawaran', component: PenawaranView, meta: {login: true}},
  {path: '/profile', name: 'profile', component: ProfileView, meta: {login: true}},
  {path: '/cartitem', name: 'cartitem', component: CartItemView, meta: {login: true}},
  {path: '/orderlist', name: 'orderlist', component: OrderListView, meta: {login: true}},
  {path: '/review', name: 'review', component: ReviewCart, meta: {login: true}},
  {path: '/upload', name: 'upload', component: UploadPenawaranView, meta: {login: true}},
  {path: '/order-detail/:id/:supplier', name: 'order-detail', component: OrderDetailView, meta: {login: true}},
  {path: '/login', name: 'login', component: LoginView, meta: {guest: true}},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if(to.matched.some(record => record.meta.login)){
    if (!store.getters.GET_AUTH_TOKEN){
      next({
        name: 'login'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)){
    if (store.getters.GET_AUTH_TOKEN) {
      next({
        name: 'home'
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router
